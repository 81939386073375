import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import OneSignal from 'react-onesignal';
import axios from "axios";

function App() {
    const APP_ID = '1a8ec49c-7386-423a-97bd-a3e99340d8d7';
    const REGISTER_ENDPOINT = 'http://10.96.113.41:5001/api/device/register';
    const NOTIFICATION_APPID = '77d07e27-bb57-8a1e-1021-3a06b89acd8b';

    useEffect(() => {
        OneSignal.init({appId: APP_ID, allowLocalhostAsSecureOrigin: true}).then(() => {
            OneSignal.showNativePrompt();

            OneSignal.on('subscriptionChange', function (isSubscribed: boolean) {
                if (isSubscribed) {
                    OneSignal.getUserId().then((userId: any) => {
                        console.log('userId', userId);
                        let data = {
                            deviceCode: userId,
                            deviceType: 5,
                            deviceModel: 'web',
                            appId: NOTIFICATION_APPID,
                            userCode: '00123_LOCAL',
                            company: 1,
                            type: 1
                        };
                        console.log(JSON.stringify(data));
                    });
                }
            });
        });

        OneSignal.on('notificationDisplay', function (event: any) {
            console.log('OneSignal notification displayed:', event);
        });

        OneSignal.on('notificationDismiss', function (event: any) {
            console.log('OneSignal notification dismissed:', event);
        });
    });

    return (
        <>hi</>
    );
}

export default App;
